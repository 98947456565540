// Helper function to calculate distance between two points using Haversine formula
function calculateDistance(lat1, lon1, lat2, lon2) {
    // Check for null or undefined coordinates
    if (lat1 == null || lon1 == null || lat2 == null || lon2 == null) {
        console.warn('Invalid coordinates in calculateDistance:', { lat1, lon1, lat2, lon2 });
        return Infinity; // Return a large distance to prevent matching
    }
    
    const R = 6371; // Earth's radius in km
    const dLat = toRad(lat2 - lat1);
    const dLon = toRad(lon2 - lon1);
    
    const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
              Math.cos(toRad(lat1)) * Math.cos(toRad(lat2)) * 
              Math.sin(dLon/2) * Math.sin(dLon/2);
    
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c;
    
    console.log('Distance calculation:', {
        points: { lat1, lon1, lat2, lon2 },
        distance: distance.toFixed(2) + ' km'
    });
    
    return distance;
}

function toRad(degrees) {
    return degrees * (Math.PI/180);
}

// Check if two locations are close enough (within maxDistance km)
function areLocationsClose(loc1Lat, loc1Lng, loc2Lat, loc2Lng, maxDistance = 400) {
    // Check for invalid coordinates
    if (loc1Lat == null || loc1Lng == null || loc2Lat == null || loc2Lng == null ||
        isNaN(loc1Lat) || isNaN(loc1Lng) || isNaN(loc2Lat) || isNaN(loc2Lng)) {
        console.warn('Invalid coordinates in areLocationsClose:', { loc1Lat, loc1Lng, loc2Lat, loc2Lng });
        return false;
    }
    
    const distance = calculateDistance(loc1Lat, loc1Lng, loc2Lat, loc2Lng);
    const isClose = distance <= maxDistance;
    
    console.log('Location proximity check:', {
        distance: distance.toFixed(2) + ' km',
        maxDistance: maxDistance + ' km',
        isClose: isClose
    });
    
    return isClose;
}

// Convert miles to kilometers
function milesToKm(miles) {
    return miles * 1.60934;
}

// Extract state from location string
function extractState(location) {
    if (!location) return null;
    
    // First try to match the pattern "XX, " where XX is the state code
    const stateCommaMatch = location.match(/([A-Z]{2}),\s/);
    if (stateCommaMatch) return stateCommaMatch[1];
    
    // Then try to match any 2-letter uppercase code
    const stateMatch = location.match(/\b([A-Z]{2})\b/);
    return stateMatch ? stateMatch[1] : null;
}

// Main matching function
export function findMatches(loads, filters) {
    // Create a map to store matches by loadId
    const matchesMap = new Map();
    
    console.log('Starting match process with filters:', filters);
    
    // Log a sample load to understand structure
    if (Object.keys(loads).length > 0) {
        const firstSource = Object.keys(loads)[0];
        if (loads[firstSource] && loads[firstSource].length > 0) {
            console.log('Sample load structure:', loads[firstSource][0]);
        }
    }
    
    // Process each load
    for (const source in loads) {
        for (const load of loads[source]) {
            // Process against each filter
            for (const filter of filters) {
                let matchType = null;
                
                // Extract states for route matching
                const loadPickupState = extractState(load.pickupLocation);
                const loadDeliveryState = load.deliveryLocation ? extractState(load.deliveryLocation) : null;
                const filterPickupState = extractState(filter.fPickupLocation);
                const filterDeliveryState = filter.fDeliveryLocation ? extractState(filter.fDeliveryLocation) : null;
                
                // Debug state extraction
                console.log('State extraction:', {
                    loadPickup: load.pickupLocation,
                    loadPickupState,
                    loadDelivery: load.deliveryLocation,
                    loadDeliveryState,
                    filterPickup: filter.fPickupLocation,
                    filterPickupState,
                    filterDelivery: filter.fDeliveryLocation,
                    filterDeliveryState
                });
                
                // Get pickup radius from filter or use default
                const pickupRadius = filter.fPickupRadius || 40; // Default to 40 km if not specified
                
                // Check for full match - only if delivery location exists
                if (loadDeliveryState && loadPickupState === filterPickupState && 
                    loadDeliveryState === filterDeliveryState) {
                    
                    // Get the correct coordinate properties from the load
                    const originLat = load.origin_lat || load.originLat;
                    const originLng = load.origin_lng || load.originLng;
                    const destLat = load.destination_lat || load.destinationLat;
                    const destLng = load.destination_lng || load.destinationLng;
                    
                    console.log('Full match coordinates check:', {
                        originLat, originLng, destLat, destLng
                    });
                    
                    // Verify exact location match using coordinates
                    const pickupClose = areLocationsClose(
                        parseFloat(originLat), 
                        parseFloat(originLng),
                        parseFloat(filter.fPickupLat), 
                        parseFloat(filter.fPickupLng),
                        pickupRadius // Use filter's pickup radius
                    );
                    
                    const deliveryClose = areLocationsClose(
                        parseFloat(destLat), 
                        parseFloat(destLng),
                        parseFloat(filter.fDeliveryLat), 
                        parseFloat(filter.fDeliveryLng),
                        100 // Stricter distance for full match
                    );
                    
                    if (pickupClose && deliveryClose) {
                        matchType = 'full';
                        console.log('Full match found!');
                    }
                }
                
                // Check for partial match if not already a full match
                if (!matchType) {
                    if (loadPickupState === filterPickupState) {
                        console.log('State match found for:', {
                            loadId: load.loadId,
                            loadPickupState,
                            filterPickupState,
                            load: load
                        });
                        
                        // Get the correct coordinate properties from the load
                        const originLat = load.origin_lat || load.originLat;
                        const originLng = load.origin_lng || load.originLng;
                        const destLat = load.destination_lat || load.destinationLat;
                        const destLng = load.destination_lng || load.destinationLng;
                        
                        console.log('Load coordinates:', {
                            originLat, originLng, destLat, destLng
                        });
                        
                        // Verify pickup location match using coordinates
                        const pickupClose = areLocationsClose(
                            parseFloat(originLat), 
                            parseFloat(originLng),
                            parseFloat(filter.fPickupLat), 
                            parseFloat(filter.fPickupLng),
                            pickupRadius // Use filter's pickup radius
                        );

                        console.log('Pickup close?', pickupClose);

                        if (pickupClose) {
                            // If filter has no delivery location, it's a match based on pickup only
                            if (!filter.fDeliveryLocation) {
                                matchType = 'partial';
                                console.log('Partial match found (pickup only)');
                            } 
                            // If filter has delivery location, check delivery match
                            else if (load.deliveryLocation) {
                                const deliveryClose = areLocationsClose(
                                    parseFloat(destLat), 
                                    parseFloat(destLng),
                                    parseFloat(filter.fDeliveryLat), 
                                    parseFloat(filter.fDeliveryLng),
                                    400 // More lenient distance for partial match
                                );
                                
                                if (deliveryClose) {
                                    matchType = 'partial';
                                    console.log('Partial match found (pickup and delivery)');
                                }
                            }
                        }
                        // Add semi-partial match based only on state
                        else if (!matchType) {
                            // If we didn't find a coordinate-based match, but states match
                            matchType = 'semipartial';
                            console.log('Semi-partial match found (state only)');
                        }
                    }
                }
                
                // If we found a match, add or update it in the map
                if (matchType) {
                    const distance = (load.deliveryLocation && filter.fDeliveryLocation) ? calculateDistance(
                        parseFloat(load.destination_lat),
                        parseFloat(load.destination_lng),
                        parseFloat(filter.fDeliveryLat),
                        parseFloat(filter.fDeliveryLng)
                    ) : null;

                    const matchKey = `${load.loadId}-${source}-${matchType}`;
                    if (!matchesMap.has(matchKey)) {
                        matchesMap.set(matchKey, {
                            match: matchType,
                            boardIds: [filter.bID],
                            loadId: load.loadId,
                            source: source,
                            distance: distance,
                            pickupLocation: load.pickupLocation,
                            deliveryLocation: load.deliveryLocation
                        });
                    } else {
                        // Add this board ID to existing match if not already present
                        const existingMatch = matchesMap.get(matchKey);
                        if (!existingMatch.boardIds.includes(filter.bID)) {
                            existingMatch.boardIds.push(filter.bID);
                        }
                    }
                    
                    // Log the match for debugging
                    console.log('Match found:', {
                        type: matchType,
                        boardId: filter.bID,
                        loadId: load.loadId,
                        source: source,
                        pickupLocation: load.pickupLocation,
                        deliveryLocation: load.deliveryLocation,
                        filterPickup: filter.fPickupLocation,
                        filterDelivery: filter.fDeliveryLocation
                    });
                }
            }
        }
    }
    
    // Convert map values to array
    return Array.from(matchesMap.values());
}
